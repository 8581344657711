import { USERS_UPDATE, USERS_LOADING, MISC_UPDATE, LOADING_TRIAL_PERIOD } from "../actions/types";
import { UserDetails, UserProfile } from "../api/users.interface";

export interface MiscState {
    loadingTrialPeriod?: boolean;
    trialPeriod?: number;
}

const InitialState: MiscState = {
    loadingTrialPeriod: false,
};

export const MiscReducer = (state: MiscState = InitialState, action: any) => {
    switch (action.type) {
        case LOADING_TRIAL_PERIOD:
            return {
                ...state,
                loadingTrialPeriod: action.payload,
            };
        case MISC_UPDATE:
            return {
                ...state,
                ...action.payload,
            };
    }
    return state;
}

export default MiscReducer;